<template>
  <div>
    <MainHeaderAlt />

    <div class="py-20">
      <img src="@/assets/onboarding.svg" class="mx-auto w-auto mb-10" />
      <div class="mx-auto max-w-md">
        <header class="mb-10">
          <h1 class="text-3xl text-prasset-gray-900">Welkom bij Prasset!</h1>
          <p class="text-lg text-gray-600 mb-3">Kies hier of je een demo project wilt aanmaken of toch liever zelf aan de slag wilt.</p>
          <p class="text-sm text-black">tip: Geen ervaring? Maak dan een demoproject aan om een indruk te krijgen van de mogelijkheden van prasset.</p>
        </header>
        <router-link class="button button--opague mr-3" :to="{ name: 'project-create-demo' }">Demo project aanmaken</router-link>
        <router-link class="button button--outlined" :to="{ name: 'project-create' }">Start een nieuw project</router-link>
        <!-- <router-link class="button button--clear" :to="{ name: '' }">Overslaan</router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
import MainHeaderAlt from '@/modules/core/views/components/MainHeaderAlt';

export default {
  components: {
    MainHeaderAlt,
  },
};
</script>
